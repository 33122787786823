import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import pusher from '../pusher';

export default function SendPage() {
  const history = useHistory();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const { token } = useParams();
  const tokenRef = useRef();
  const messageRef = useRef();

  useEffect(() => {
    tokenRef.current.value = token ?? '';
    messageRef.current.value = '';
  }, [token]);

  const onClickBackButton = () => history.push('/');

  const onSubmitHandler = e => {
    e.preventDefault();

    const shareToken = tokenRef.current.value;
    const message = messageRef.current.value;
    const channel = pusher().subscribe('private-' + shareToken);
    channel.bind('pusher:subscription_succeeded', () => {
      const triggered = channel.trigger('client-share-event', {
        message: message,
      });
      console.log('success? ' + triggered);
      if (triggered) {
        console.log('Sending: token=' + shareToken + ', "' + message + '"');
        setSuccess('Message sent successfully.');
      } else {
        console.log('Failed to send with token=' + shareToken + ', "' + message + '"');
        setError('Failed to send message.');
      }
    });
  };

  return (
    <>
      <h2 className="my-3">Send Page</h2>
      {success && <Alert variant="success">{success}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Container>
        <Card className="m-auto" style={{ maxWidth: '1000px' }}>
          <Card.Body>
            <Form onSubmit={onSubmitHandler}>
              <Form.Group as={Row} controlId="send.token">
                <Form.Label column sm={3}>
                  Share Token
                </Form.Label>
                <Col>
                  <Form.Control type="text" ref={tokenRef} placeholder="Recipient's Share Token" required />
                </Col>
              </Form.Group>
              <Form.Group controlId="send.message">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" ref={messageRef} rows={5} required />
              </Form.Group>
              <div className="d-flex flex-row-reverse flex-wrap justify-content-center align-items-center">
                <Button variant="primary" type="submit" className="m-1">
                  Send
                </Button>
                <Button variant="secondary" onClick={onClickBackButton} className="m-1">
                  Back
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
