export const styles = {
  buttonContainer: {
    maxWidth: '1000px',
    height: '200px',
  },
  button: {
    border: '0.5px',
    borderRadius: '0px',
    borderStyle: 'solid',
  },
  buttonLine: {
    background: 'white',
    width: '20px',
    height: '3px',
  },
};
