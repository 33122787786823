import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { PageType } from '../PageContext';
import { styles } from '../style';

const ButtonBorderLine = () => <span className="my-2" style={styles.buttonLine}></span>;

const CustomButton = ({ displayString, onClick }) => {
  return (
    <Button
      variant="primary"
      onClick={onClick}
      className="shadow-none col-sm text-center d-flex flex-column justify-content-center align-items-center"
      style={styles.button}
    >
      <h3>{displayString}</h3>
      <ButtonBorderLine />
    </Button>
  );
};

export default function WelcomePage() {
  const history = useHistory();
  const onClickReceive = () => history.push(PageType.Receive);
  const onClickSend = () => history.push(PageType.Send);
  return (
    <>
      <h2 className="my-3">Share anonymously now!</h2>
      <span className="d-flex row justify-content-center px-0 w-100" style={styles.buttonContainer}>
        <CustomButton displayString="Receive" onClick={onClickReceive} />
        <CustomButton displayString="Send" onClick={onClickSend} />
      </span>
    </>
  );
}
