import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ReceivePage from './component/ReceivePage';
import SendPage from './component/SendPage';
import WelcomePage from './component/WelcomePage';
import PageProvider from './PageContext';

export default function App() {
  return (
    <PageProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={WelcomePage} />
          <Route exact path="/receive" component={ReceivePage} />
          <Route exact path="/send/:token?" component={SendPage} />
        </Switch>
      </Router>
    </PageProvider>
  );
}
