import React, { useState, useEffect } from 'react';
import { Alert, Navbar } from 'react-bootstrap';
import https from 'https';

export const PageType = {
  Welcome: '/',
  Receive: '/receive',
  Send: '/send',
};

export const PageContext = React.createContext();

export default function PageProvider({ children }) {
  const [currentPage, setCurrentPage] = useState(PageType.Welcome);
  const [showNotice, setShowNotice] = useState(true);

  useEffect(() => {
    https.get('https://anonymousshare.herokuapp.com/wake', resp => {
      console.log('Wake auth server, Status=' + resp.statusCode);
    });
  }, []);

  return (
    <PageContext.Provider
      value={{
        currentPage,
        setCurrentPage,
      }}
    >
      <div className="vh-100 mx-0">
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">{children}</div>
      </div>
      {showNotice && (
        <>
          <Navbar fixed="bottom" className="justify-content-center">
            <Alert variant="info" onClose={() => setShowNotice(false)} dismissible>
              Share content will not be saved or cached on any of our server
            </Alert>
          </Navbar>
        </>
      )}
    </PageContext.Provider>
  );
}
