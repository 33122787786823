import copyToClipboard from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import React from 'react';
import { Button, Card, Container, FormControl } from 'react-bootstrap';
import { Files } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import usePusher from '../hooks/usePusher';

function getSendUrl(token) {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    '/send/' +
    token
  );
}

const InputFieldWithClipboard = ({ content }) => {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center my-2">
      <input type="text" className="form-control" placeholder="Token" defaultValue={content} readOnly />
      <Button variant="link" onClick={() => copyToClipboard(content)}>
        <Files className="mx-2" color="royalblue" size={20} />
      </Button>
    </div>
  );
};

const ShareToken = ({ shareToken, shareUrl, shareTitle }) => {
  return (
    <>
      <h5>Your Share Token</h5>
      <InputFieldWithClipboard content={shareToken} />
      <InputFieldWithClipboard content={shareUrl} />
      <QRCode value={shareUrl} />
      <div className="d-flex flex-row justify-content-center">
        <EmailShareButton className="mx-1" url={shareUrl} subject={shareTitle} body="">
          <EmailIcon size={32} round />
        </EmailShareButton>
        <TelegramShareButton className="mx-1" title={shareTitle} url={shareUrl}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <WhatsappShareButton className="mx-1" title={shareTitle} url={shareUrl} separator=":: ">
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <LineShareButton className="mx-1" url={shareUrl} title={shareTitle}>
          <LineIcon size={32} round />
        </LineShareButton>
      </div>
    </>
  );
};

const ShareTokenWrapper = ({ shareToken }) => {
  if (!shareToken) {
    return (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
  const shareUrl = getSendUrl(shareToken);
  return <ShareToken shareUrl={shareUrl} shareTitle="Hi, here is my share link" shareToken={shareToken} />;
};

export default function ReceivePage() {
  const history = useHistory();

  const onClickBackButton = () => history.push('/');

  const { message, shareToken } = usePusher();

  return (
    <>
      <h2 className="my-3">Receive Page</h2>
      <Container>
        <Card className="m-auto text-center" style={{ maxWidth: '500px' }}>
          <Card.Body>
            <ShareTokenWrapper shareToken={shareToken} />
            {message && (
              <div className="my-2">
                <h5>Received Message</h5>
                <FormControl as="textarea" aria-label="Received Message" rows={5} value={message} readOnly />
              </div>
            )}
            <div className="d-flex justify-content-center mt-2">
              <Button variant="secondary" onClick={onClickBackButton}>
                Back
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
