import Pusher from 'pusher-js';

Pusher.logToConsole = true;

export default function pusher() {
  return new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    forceTLS: true,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
  });
}
