import { useEffect, useState } from 'react';
import { v5 as uuidv5 } from 'uuid';
import shortHash from 'short-hash';
import Pusher from 'pusher-js';

import pusher from '../pusher';

Pusher.logToConsole = true;

export default function usePusher() {
  const [message, setMessage] = useState();
  const [shareToken, setShareToken] = useState();

  useEffect(() => {
    const uuid = uuidv5(Date.now().toString(), uuidv5.URL);
    const token = shortHash(uuid);
    const channelName = 'private-' + token;
    const pusherInstance = pusher();
    const channel = pusherInstance.subscribe(channelName);

    channel.bind('client-share-event', data => {
      console.log('Reveive data');
      console.log(data);
      setMessage(data.message);
    });
    channel.bind('pusher:subscription_succeeded', () => {
      console.log({ token });
      setShareToken(token);
    });

    // disconnect on leaving this page
    return () => pusherInstance?.disconnect();
  }, []);

  return { message, shareToken };
}
